.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #0e1117;
  margin: 0;
}

.coming-soon-text {
  color: white;
  font-family: 'Nunito', sans-serif;
  font-size: 2.5rem;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: white;
  background-color: #151b23;
  font-family: 'Nunito', sans-serif;
  font-size: 0.8rem;
}
